var _a;
import { create } from "zustand";
import { isMeldAssigned } from "@pm-frontend/shared/utils/meld-utils";
export const getCalendarDroppableId = (personaType, persona, date) => {
  return `${personaType}-${persona.id}-${date}`;
};
export const getPersonaFromDroppableId = (droppableId) => {
  const splitId = droppableId.split("-");
  const personaType = splitId[0] === "vendor" ? "vendor" : "agent";
  const personaId = splitId[1];
  const timeString = splitId[2];
  return [personaType, personaId, timeString];
};
export const getCalendarDraggableId = (meld) => {
  let draggableType = "rescheduleMeld";
  if (!meld.started) {
    if (!isMeldAssigned(meld)) {
      draggableType = "assignAndScheduleMeld";
    }
  }
  return getDraggableId(draggableType, meld.id);
};
const getDraggableId = (draggableType, meldId) => {
  return `${draggableType}-${meldId}`;
};
export const getMeldInfoFromDraggableId = (draggableId) => {
  const splitId = draggableId.split("-");
  const draggableType = splitId[0];
  const meldId = splitId[1];
  return [draggableType, meldId];
};
const doesSegmentMatchAppt = (segment, appointment) => {
  var _a2;
  if (segment.event.dtstart && segment.event.dtend && ((_a2 = appointment.availability_segment) == null ? void 0 : _a2.event.dtstart) && appointment.availability_segment.event.dtend) {
    return segment.event.dtstart === appointment.availability_segment.event.dtstart && segment.event.dtend === appointment.availability_segment.event.dtend;
  }
  return false;
};
export const isSegmentScheduled = (segment, meld) => {
  if (meld.managementappointment.length > 0) {
    return meld.managementappointment.some((appt) => doesSegmentMatchAppt(segment, appt));
  } else if (meld.vendorappointment.length > 0) {
    return meld.vendorappointment.some((appt) => doesSegmentMatchAppt(segment, appt));
  }
  return false;
};
const CALENDAR_REDESIGN_TOGGLE_SESSION_STORAGE_KEY = `ui-calendar-redesign-enabled-${((_a = window.PM.user) == null ? void 0 : _a.id) || "karma-test-fix"}`;
const readCalendarStateFromLocalStorage = () => {
  try {
    const rawKey = window.localStorage.getItem(CALENDAR_REDESIGN_TOGGLE_SESSION_STORAGE_KEY);
    if (rawKey !== null) {
      return !!JSON.parse(rawKey);
    }
  } catch (e) {
    return false;
  }
  return false;
};
const writeCalendarStateToLocalStorage = (value) => {
  try {
    window.localStorage.setItem(CALENDAR_REDESIGN_TOGGLE_SESSION_STORAGE_KEY, JSON.stringify(value));
    return;
  } catch (e) {
    return;
  }
};
export const shouldShowCalendarRedesignDueToMobile = window.matchMedia(`(min-width: 800px)`);
export const useCalendarRedesignToggleStore = create((set) => ({
  enabled: shouldShowCalendarRedesignDueToMobile.matches && readCalendarStateFromLocalStorage(),
  actions: {
    toggleCalendarRedesignEnabled: () => {
      set((state) => {
        writeCalendarStateToLocalStorage(!state.enabled);
        location.reload();
        return { enabled: !state.enabled };
      });
    }
  }
}));
export const isEventAvailability = (event) => {
  var _a2, _b;
  return !(typeof ((_a2 = event.managementavailabilitysegment) == null ? void 0 : _a2.scheduled_management_appointment) === "number" || typeof ((_b = event.vendoravailabilitysegment) == null ? void 0 : _b.scheduled_vendor_appointment) === "number");
};
